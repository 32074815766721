






































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'b-table-pag'
})
export default class BTablePag extends Vue {
    @Prop({
        required: true,
        default: 10
    })
    private perPage!: number;

    @Prop({
        required: true,
        default: 0
    })
    private curPage!: number;

    @Prop({
        required: true,
        default: 0
    })
    private pgRows!: number;

    private perPageLst = [{ value: 10, text: '10' }, { value: 15, text: '15' }, { value: 20, text: '20' }];

    private pageStr = 1;

    private get perPageVal(): number {
        if (this.pgRows > 0) {
            if (this.curPageVal > this.maxPg) { this.curPageVal = this.maxPg; }
        }
        return this.perPage;
    }

    private set perPageVal(val: number) {
        this.$emit('chgPerPage', val);
    }

    private get curPageVal(): number {
        return this.curPage;
    }

    private set curPageVal(val: number) {
        this.$emit('chgCurPage', val);
    }

    private get maxPg() {
        if (this.pgRows === 0) { return 1; }
        return Math.ceil(this.pgRows / this.perPage);
    }

    private up() {
        if (this.curPageVal < this.maxPg) {
            this.curPageVal++;
        }
    }

    private down() {
        if (this.curPageVal > 1) { this.curPageVal--; }
    }

    private get navTxt(): string {
        let txt = '';
        if (this.pgRows > 0) {
            if (this.curPageVal < this.maxPg) {
                txt = ((this.curPageVal - 1) * this.perPageVal + 1) + '-' + (this.curPageVal * this.perPageVal);
            } else {
                txt = ((this.curPageVal - 1) * this.perPageVal + 1) + '-' + this.pgRows;
            }
            txt += ' из ' + this.pgRows;
        }
        return txt;
    }

    private created() {
        // eslint-disable-next-line
        const that = this;
        this.$watch('curPage', function () {
            that.pageStr = that.curPage;
        });
    }

    private chgPageStr() {
        const page = Number(this.pageStr);
        if (page > this.maxPg) {
            this.pageStr = this.maxPg;
        } else if (page < 1) {
            this.pageStr = 1;
        }
        this.curPageVal = Number(this.pageStr);
    }


    private onlyDigit($event: any) {
        const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if (keyCode === 13) {
            this.chgPageStr();
            $event.preventDefault();
        }
        if ((keyCode < 48 || keyCode > 57)) {
            $event.preventDefault();
        }
    }
}
