
































































import { Component, Vue } from 'vue-property-decorator';
import store from '@/services/store';
import {string} from "@amcharts/amcharts4/core";

@Component({
    name: 'd-filter-form'
})
export default class DFilterForm extends Vue{
    public yearItem = 'Год';
    public currentYear = (new Date()).getFullYear();
    public yearsArr: number[] = [];
    public filterNameParams = [
        {
            name_ru: 'Программы',
            name_kk: 'Программы',
            name_en: 'Programs'
        }
    ];
    private dictProgramVal: any | null = '';
    private programArr: any = [];
    private dictSgpParentDocVal = '';
    private region = '';
    public all = {
        name_ru: 'Все',
        name_en: 'All',
        name_kk: 'Барлық',
        id: ''
    };
    public addRecords = {
        name_ru: 'Добавить',
        name_en: 'Add',
        name_kk: 'Добавить'
    }
    public programsList: any = [];
    private programId: any | null = null;

    private mounted() {
        const currentYear = this.currentYear;
        this.listOfYears(currentYear);
        this.getObl();
        this.loadPrograms();
    }

    private async getObl() {
        const region = store.state._instanceCode;
        if (region===null || region.length===0) {
            this.region = '00';
        } else {
            this.region = region;
        }
        // console.log('region: ' + region);
        return region;
    }

    private usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    //-----Список годов в фильтрации по годам----//
    private listOfYears(currentYear: number | null){
        this.yearsArr = [];
        if (currentYear!==null){
            for (let i=currentYear; i>currentYear-5; i--){
                this.yearsArr.push(i);
            }
        }
    }

    private padLeadingZeros(num: string, size: number) {    // -------добавление нулей после значения в зависимости от размера значения
        let s = num;
        while (s.length < size) { s = s + '0'; }
        return s;
    }

    // ----Извлекается справочник программ----//
    private async loadPrograms() {   // ---Извлекается справочник Программ---//
        let response: any = null;
        const result = [];
        this.programArr = [];
        this.dictSgpParentDocVal = '';
        try {
            const region = this.padLeadingZeros(this.region, 9);
            // console.log('year:' + params +' region: ' + region);
            // response = await fetch('/api/forecast/year-programs?year=' + this.currentYear + '&codeKato=' + region);
            response = await fetch('/api/program-passport/get-passport?year=' + this.currentYear + '&prgId=');
            response = await response.json();
            // console.log('response: ' + JSON.stringify(response));
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки Программ', error.toString());
        }
        if (response.length > 0) {
            result.push(this.setNameLangs(this.all, 'program'));
            for (const el of response) {
                result.push(this.setNameLangs(el.program, 'program'));
            }
        }
        if (this.dictProgramVal !== null) {
            this.dictProgramVal = this.setNameLangs(this.all, 'program');
            this.dictProgramVal = this.setNameLangs(this.dictProgramVal, 'program');
        }
        this.programArr = result;
        await this.loadPassportData();
    }

    private async loadPassportData() {
        if (this.dictProgramVal !== null){
            this.programId = this.dictProgramVal.id;
        } else {
            this.programId = "";
        }
        this.$emit('listOfProgramsInPassport', this.currentYear, this.programId);
    }

    // ----Создание объектов на основе переданных данных из справочников----//
    private setNameLangs(obj: any | null, codeName: any | null) {
        let txt = obj.name_ru;
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    // ------Метод для отображения сообщения с ошибками----//
    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            autoHideDelay: 5000,
            variant: variant,
            appendToast: true
        });
    } // сообщение с ошибкой

    private addClk(){
        // this.edit = true;
        this.$emit('showProgramListForm', true);
    }
}
