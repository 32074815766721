













































































































import { Component, Vue } from 'vue-property-decorator';
import BTablePag from "@/modules/program-monitor2/components/b-table-pag.vue";
import DFilterForm from '@/modules/development-program/filterForm.vue';
import DFormOfProgramModules from '@/modules/development-program/formOfProgramModules.vue';
import VueElementLoading from 'vue-element-loading';
import store from '@/services/store';
import axios from "axios";

@Component({
    name: "listOfPrograms",
    components: {
        'd-filter-form': DFilterForm,
        'b-table-pag': BTablePag,
        'd-form-program-modules': DFormOfProgramModules,
        'loading': VueElementLoading
    }
})
export default class DListOfPrograms extends Vue{
    public modalVisible = false;
    private regionKato = '';
    private regionKatoPvldr = '';
    private programArr: any[] = [];
    private isArrayEmpty = true;
    private curYear = (new Date()).getFullYear();
    private selectedRow = 'selectedRow';
    private unselectedRow = 'unselectedRow';
    private programId = 0;
    public programObj = {};
    public prgDocNum = 0;
    public indicatorNum = 0;
    public eventNum = 0;
    public unitNum = 0;
    private programsList: any[] = [];
    private prgId: any;
    private currentYear: any;
    private edit = false;
    private loading = false;
    private oblast: any;
    private currentProgramPassport = {
        programPassportId: '',
        programId: '',
        nameRu: '',
        nameKk: '',
        nameEn: '',
        startDate: '',
        endDate: '',
        expired: false,
        obl: ''
    };

    private mounted() {
        this.getObl();
        this.loading=true;
    }

    private usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    private closePassport(close: any){
        this.edit = close;
    }

    private async getObl() {
        if (store.state._instanceCode.length > 0) {
            // console.log('obl: ' + JSON.stringify(obl));
            this.regionKato = this.padLeadingZeros(store.state._instanceCode, 9);
        } else {
            this.regionKato = this.padLeadingZeros('00', 9);
            this.regionKatoPvldr = this.padLeadingZeros('55', 9);
        }

        let oblast = '';
        try {
            await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                .then(response => response.json())
                .then(json => {
                    oblast = json.obl;
                });

            this.oblast = this.padLeadingZeros(oblast.toString(), 6);
            // console.log('this.oblast: ' + this.oblast);
            await fetch('/api-py/kato-code-from-budget/' + this.oblast)
            .then(response => response.json())
            .then(json => {
                this.currentProgramPassport.obl = json;
                // console.log('json: ' + this.currentProgramPassport.obl);
            })
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
        }
    }

    private padLeadingZeros(num: string, size: number) {    // -------добавление нулей после значения в зависимости от размера значения
        let s = num;
        while (s.length < size) { s = s + '0'; }
        return s;
    }

    private showProgramListForm(show: any){
        if (show !== null){
            this.getDataFromFilter(show);
        }
    }

    private getDataFromFilter(show: boolean){
        if (show){
            this.modalVisible = true;
            this.loadPrograms();
        }
    }

    private saveProgram(result: boolean){
        // console.log('result: ' + result);
        if (!result){
            this.modalVisible = result;
            this.programId = 0;
        } else {
            // console.log('this.programObj: ' + JSON.stringify(this.programObj));
            if (this.programId!=0) {
                this.saveProgramToPassport(this.programId);
            }
        }
    }

    //-----Сохранить данные в БД
    private async saveProgramToPassport(program: number) {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const result = await axios.post(`/api/program-passport/add/${program}`, {headers});
            if (result.status === 200) {
                // console.log('result: ' + JSON.stringify(result.data));
                if (result.data === true){
                    await this.listOfProgramsInPassport(this.currentYear, this.prgId);
                    this.modalVisible = false;
                    this.programId = 0;
                    this.makeToast('success', 'Сохранение', 'Данные сохранены!');
                } else {
                    this.makeToast('danger', 'Сохранение', 'Выбранная программа ранее уже была добавлена');
                }

            } else {
                this.makeToast('danger', 'Сохранение', `Ошибка ${result.status} ${result.statusText}`);
            }
        } catch (error) {
            console.log(error);
        }
    }

    private async deleteRow(prgItem: any, prgIndex: any) {
        // console.log('prgIndex: ' + prgIndex + ' programID: ' + prgItem.program.id + ' prgItem: ' + JSON.stringify(prgItem));
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const result = await axios.post(`/api/program-passport/del-program/${prgItem.id}/${prgItem.program.id}`, {headers});
            if (result.status === 200) {
                // console.log('result: ' + JSON.stringify(result.data));
                if (result.data === true) {
                    await this.listOfProgramsInPassport(this.currentYear, this.prgId);
                    this.modalVisible = false;
                    this.makeToast('success', 'Удаление', 'Данные успешно удалены');
                } else {
                    this.makeToast('danger', 'Удаление', 'Удаление программы невозможно, так как имеются Целевые индикаторы. Пожалуйста сначала удалите целевые индикаторы');
                }

            } else {
                this.makeToast('danger', 'Удаление', `Ошибка ${result.status} ${result.statusText}`);
            }
        } catch (error) {
            console.log(error);
        }
    }

    private chooseProgram(elItem: any, elItemIndex: any){
        if (elItemIndex!=null){
            this.programId = elItem.id;
            this.programObj = elItem;
        }
    }

    private async listOfProgramsInPassport(currentYear: any, prgId: any) {
        this.prgId = prgId;
        this.currentYear = currentYear;
        let response: any = null;
        try {
            // console.log('currentYear: ' + currentYear + ' prgId: ' + prgId);
            response = await fetch('/api/program-passport/get-passport?year=' + currentYear + '&prgId=' + prgId);
            response = await response.json();
            this.programsList = response;
            // console.log('this.programsList: ' + JSON.stringify(this.programsList));
            let countProgIds = 0;
            for (const res of response){
                if (res.id!==null){
                    countProgIds += 1;
                }
            }
            this.prgDocNum = countProgIds;
            this.loading=false;
            // console.log('this.programsList: ' + JSON.stringify(this.programsList));
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки Паспорта программ', error.toString());
        }
    }

    private moveToPassport(prgId: any){
        // console.log('ProgramID: ' + JSON.stringify(prgId));
        this.edit = true;

        if (prgId!=null && prgId.program!=null){
            this.currentProgramPassport.programPassportId = prgId.id;
            this.currentProgramPassport.programId = prgId.program.id
            this.currentProgramPassport.nameRu = prgId.program.name_ru;
            this.currentProgramPassport.nameKk = prgId.program.name_kz;
            this.currentProgramPassport.nameEn = prgId.program.name_en;
            this.currentProgramPassport.startDate = prgId.startDate;
            this.currentProgramPassport.endDate = prgId.endDate;
            this.currentProgramPassport.expired = (new Date(prgId.endDate).getFullYear() < this.curYear);
            // console.log('moveToPassport.currentProgramPassport: ' + JSON.stringify(this.currentProgramPassport));
        }
    }

    // ----Извлекается справочник программ----//
    private async loadPrograms() {
        let response: any = null;
        const result = [];
        this.programArr = [];
        if (this.curYear !== null) {
            try {
                response = await fetch('/api/program-passport/year-programs?year=' + this.curYear + '&codeKato1=' + this.regionKato + '&codeKato2=' + this.regionKatoPvldr);
                response = await response.json();
                // console.log('response: ' + JSON.stringify(response));
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Программ', error.toString());
            }
            if (response.length > 0) {
                for (const el of response) {
                    result.push(this.setNameLangs(el, 'program'));
                }
                this.isArrayEmpty = false;
            }
            this.programArr = result;
        }
    }

    // ----Создание объектов на основе переданных данных из справочников----//
    private setNameLangs(obj: any | null, codeName: any | null) {
        let txt = '';
            txt = obj.name_ru;
        // console.log('txt: ' + txt)
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
            // console.log('txt: ' + txt);
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        // console.log('el: ' + JSON.stringify(el));
        return el;
    }

    // ------Метод для отображения сообщения с ошибками----//
    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            autoHideDelay: 5000,
            variant: variant,
            appendToast: true
        });
    } // сообщение с ошибкой
}
