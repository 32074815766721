









import { Component, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import DFilterForm from '@/modules/development-program/filterForm.vue';
import DListOfPrograms from '@/modules/development-program/listOfPrograms.vue';
import store from '@/services/store';

const axios = require("axios");

@Component({
    components: {
        'd-filter-form': DFilterForm,
        'd-list-of-programs': DListOfPrograms,
        'multiselect': Multiselect
    }
})

export default class DInitialForm extends Vue{
    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }
}
